import React from "react";
import { Grid, List, Header, Icon, Divider } from "semantic-ui-react";

export default () => (
  <Grid stackable columns={2}>
    <Grid.Row style={{ padding: 10 }}>
      <Grid.Column floated="left">
        <Header as="h6" content="Kontakt" style={{ marginBottom: 10 }} />
        <List style={{ fontSize: 12 }}>
          <List.Item>
            <List.Icon color="orange" name="phone" />
            <List.Content>
              <a href="tel:223307702">22 330 77 02</a>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Icon color="orange" name="mail" />
            <List.Content>
              <a href="mailto:kontakt@doradcainformatyczny.pl">
                kontakt@doradcainformatyczny.pl
              </a>
            </List.Content>
          </List.Item>
        </List>
      </Grid.Column>
      <Grid.Column floated="right">
        <div style={{ display: "flex", float: "right" }}>
          <List style={{ textAlign: "right", fontSize: 12 }}>
            <List.Item>BZGroup sp. z o.o.</List.Item>
            <List.Item>Rudna Mała 47, 36-060 Głogów Małopolski</List.Item>
            <List.Item>NIP: 5242758944</List.Item>
            <List.Item>REGON: 146647470</List.Item>
          </List>
          <Icon
            name="map signs"
            color="orange"
            style={{ margin: "25px 0 0 10px" }}
          />
        </div>
      </Grid.Column>
    </Grid.Row>
  </Grid>
);
