import React, { Component } from "react";
import {
  Container,
  Icon,
  Responsive,
  Segment,
  Sidebar,
  Visibility,
  Menu,
} from "semantic-ui-react";
import DesktopMenu from "./DesktopMenu";
import MenuItems from "./MenuItems";
import Logo from "./Logo";
import Footer from "./Footer";

const getWidth = () => {
  const isSSR = typeof window === "undefined";

  return isSSR ? Responsive.onlyTablet.minWidth : window.innerWidth;
};

class DesktopContainer extends Component {
  state = {};

  hideFixedMenu = () => this.setState({ fixed: false });
  showFixedMenu = () => this.setState({ fixed: true });

  render() {
    const { children, activePage } = this.props;
    const { fixed } = this.state;

    return (
      <Responsive
        getWidth={getWidth}
        minWidth={Responsive.onlyTablet.minWidth}
        style={{ minHeight: "100vh" }}
      >
        <Visibility
          once={false}
          onBottomPassed={this.showFixedMenu}
          onBottomPassedReverse={this.hideFixedMenu}
          style={{ height: "73px" }}
        >
          <Segment textAlign="center" vertical>
            <DesktopMenu fixed={fixed} activePage={activePage} />
          </Segment>
        </Visibility>
        {children}
      </Responsive>
    );
  }
}

type Props = {
  activePage?: string;
};

type State = {
  sidebarOpened: boolean;
};

class MobileContainer extends Component<Props, State> {
  state = { sidebarOpened: false };

  handleSidebarHide = () => this.setState({ sidebarOpened: false });

  handleToggle = () => this.setState({ sidebarOpened: true });

  render() {
    const { children, activePage } = this.props;
    const { sidebarOpened } = this.state;

    return (
      <Responsive
        as={Sidebar.Pushable}
        getWidth={getWidth}
        maxWidth={Responsive.onlyMobile.maxWidth}
        style={{ minHeight: "100vh" }}
      >
        <Sidebar
          as={Menu}
          animation="push"
          inverted
          onHide={this.handleSidebarHide}
          vertical
          visible={sidebarOpened}
        >
          <MenuItems activeItem={activePage} />
        </Sidebar>

        <Sidebar.Pusher dimmed={sidebarOpened}>
          <Container>
            <Menu pointing={false} secondary size="large">
              <Menu.Item onClick={this.handleToggle}>
                <Icon name="sidebar" />
              </Menu.Item>
              <Logo />
            </Menu>
          </Container>

          {children}
        </Sidebar.Pusher>
      </Responsive>
    );
  }
}

const ResponsiveContainer = ({ children, activePage }) => (
  <div>
    <DesktopContainer activePage={activePage}>{children}</DesktopContainer>
    <MobileContainer activePage={activePage}>{children}</MobileContainer>
  </div>
);

const HomepageLayout = ({ children, showFooter = true, activePage }) => (
  <ResponsiveContainer activePage={activePage}>
    <Segment
      vertical
      style={{
        margin: "0 0 0 0",
        padding: "0 0 0 0",
        minHeight: "calc(100vh - 175px)",
      }}
    >
      {children}
    </Segment>
    {showFooter && (
      <Segment
        vertical
        style={{
          backgroundColor: "#E8E8E8",
          height: 102,
        }}
      >
        <Container>
          <Footer />
        </Container>
      </Segment>
    )}
  </ResponsiveContainer>
);

export default HomepageLayout;
