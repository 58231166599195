import React, { Component } from "react";
import { Link } from "gatsby";
import { Menu } from "semantic-ui-react";
import Logo from "./Logo";
import MenuItems from "./MenuItems";

export default ({ fixed, activePage }) => (
  <Menu
    fixed="top"
    pointing={fixed}
    secondary={!fixed}
    position="right"
    size="small"
  >
    <Logo />
    <Menu.Menu position="right">
      <MenuItems activeItem={activePage} />
    </Menu.Menu>
  </Menu>
);
