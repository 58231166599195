import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { Menu } from "semantic-ui-react";

const MenuItem = styled(Menu.Item).attrs(() => ({
  className: "item",
}))`
  color: ${props => (props.active ? "#e1840f" : "black")} !important;
  :hover {
    background: none !important;
    color: #e1840f !important;
  }
`;

export default ({ activeItem }) => (
  <>
    <MenuItem as={Link} to="/" active={activeItem === "home" ? 1 : 0}>
      STRONA GŁÓWNA
    </MenuItem>
    <MenuItem as={Link} to="/about" active={activeItem === "about" ? 1 : 0}>
      O USŁUDZE
    </MenuItem>
    <MenuItem
      as={Link}
      to="/services"
      active={activeItem === "services" ? 1 : 0}
    >
      PAKIETY
    </MenuItem>
    <MenuItem
      as={Link}
      to="/how-to-use"
      active={activeItem === "how-to-use" ? 1 : 0}
    >
      JAK SKORZYSTAĆ?
    </MenuItem>
    <MenuItem as="a" href="https://medicover24.doradcainformatyczny.pl">
      KONTO KLIENTA
    </MenuItem>
  </>
);
